<template>
  <el-drawer
    title=""
    :visible.sync="onDrawerVisible"
    :with-header="false"
    @closed="onCloseDrawer"
    size="40%"
  >
    <section
      v-if="form"
      class="wrapper__business-stablishment-add d-flex justify-content-center align-items-center p-4"
    >
      <b-row>
        <b-col xs="12" lg="12">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <img :src="icoStores" alt="stores" width="48" />
            <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
              Modificar Establecimiento
            </h4>
          </div>
          <el-form :model="form" :rules="rules" ref="form">
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="code" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0"
                      >Código de establecimiento</label
                    >
                  </slot>
                  <el-input
                    placeholder=""
                    class=""
                    v-model="form.code"
                  ></el-input>
                </el-form-item>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="name" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Nombre Comercial</label>
                  </slot>
                  <el-input
                    placeholder=""
                    class=""
                    v-model="form.name"
                  ></el-input>
                </el-form-item>
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="city_id" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Ciudad</label>
                  </slot>
                  <el-select
                    filterable
                    placeholder="Seleccionar"
                    class=""
                    v-model="form.city_id"
                  >
                    <el-option
                      v-for="city in cities"
                      :key="city.id"
                      :label="city.name"
                      v-model="city.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="address" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Dirección</label>
                  </slot>
                  <el-input
                    placeholder=""
                    class=""
                    v-model="form.address"
                  ></el-input>
                </el-form-item>
              </b-col>
            </b-row>

            <b-row>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="email" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Correo</label>
                  </slot>
                  <el-input
                    placeholder=""
                    class=""
                    v-model="form.email"
                  ></el-input>
                </el-form-item>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <el-form-item prop="phone" class="mb-4">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Teléfono</label>
                  </slot>
                  <el-input
                    placeholder=""
                    class=""
                    v-model="form.phone"
                  ></el-input>
                </el-form-item>
              </b-col>
            </b-row>
            <el-form-item prop="website" class="mb-4">
              <slot name="label">
                <label class="font-semi-bold m-0">Sitio Web</label>
              </slot>
              <el-input
                placeholder=""
                class=""
                v-model="form.website"
              ></el-input>
            </el-form-item>

            <b-row>
              <b-col xs="12" sm="12">
                <ButtonPrimaryComponent
                  class="center mt-4"
                  title="Guardar"
                  @onSaveClick="handleSaveClick"
                />
              </b-col>
            </b-row>
          </el-form>
        </b-col>
      </b-row>
    </section>
  </el-drawer>
</template>

<script>
import { icoStores } from "@/services/resources";
import Validate from "@/assets/validate";
import tradeService from "../../../services/tradeService";
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import CitiesServices from "@/services/CitiesServices";

export default {
  name: "BusinessStablishmentAddComponent",
  data() {
    return {
      onDrawerVisible: this.visible,
      cities: [],
      icoStores,
      form: null,
      rules: {
        code: [Validate.rules.required()],
        name: [Validate.rules.required()],
        city_id: [Validate.rules.required("change")],
        address: [Validate.rules.required()],
        phone: [Validate.rules.required()],
        email: [{ ...Validate.rules.required(), ...Validate.rules.email() }]
        // website: [Validate.rules.required()]
      }
    };
  },
  watch: {
    visible(v) {
      this.onDrawerVisible = v;
    }
  },
  methods: {
    onCloseDrawer() {
      this.onDrawerVisible = false;
      this.$emit("onCloseDrawer");
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await tradeService
              .updateStablishments(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.$root.$emit("onStablishmentAdd");
              this.onCloseDrawer();
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async getCities() {
      await CitiesServices.getCities().then(response => {
        if (response.data.success) this.cities = response.data.cities;
      });
    }
  },
  components: {
    ButtonPrimaryComponent
  },
  props: {
    visible: {
      default: false
    }
  },
  mounted() {
    this.getCities();
    this.$root.$on("onStablishmentEdit", stablishment => {
      this.form = stablishment;
    });
  }
};
</script>

<style lang="scss" scoped></style>
