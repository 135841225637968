import { render, staticRenderFns } from "./StablishmentAdd.vue?vue&type=template&id=4d02e980&scoped=true"
import script from "./StablishmentAdd.vue?vue&type=script&lang=js"
export * from "./StablishmentAdd.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d02e980",
  null
  
)

export default component.exports