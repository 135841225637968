import Api from "@/config/Api";

export default {
  uploadSign(data, config) {
    return Api().post("/signature/upload-file-to-sing", data, config);
  },
  getConfigDocumentSequences(id) {
    let url = "/business/config/documents";
    url = id !== "" ? `${url}?ep=${id}` : url;
    return Api().get(url);
  },
  updateConfigDocumentSequences(data) {
    return Api().post("/business/config/documents", data);
  }
};
