<template>
  <v-tour name="tour" :steps="steps" :options="options" :callbacks="callbacks">
  </v-tour>
</template>

<script>
export default {
  name: "BusinessTourComponent",
  data() {
    return {
      callbacks: {
        onSkip: this.tourStepFinish,
        onFinish: this.tourStepFinish
      },
      options: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Cerrar",
          buttonPrevious: "Anterior",
          buttonNext: "Continuar",
          buttonStop: "Finalizar"
        }
      },
      steps: [
        {
          target: "#app",
          content: `
            <h5 class="font-bold">1/5</h5>
            <h6 class="text-color-secondary font-bold">Completa la información de tu negocio </h6>
            <p class="m-0">Puedes completar la información de facturación como firma electrónica y establecimientos luego.</p>
          `
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">2/5</h5>
            <h6 class="text-color-secondary font-bold">Agrega la firma electrónica para poder emitir documentos y firmarlos</h6>
          `
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">3/5</h5>
            <h6 class="text-color-secondary font-bold">Agrega una secuencia en caso que hayas facturado electrónicamente en otro sistema</h6>
          `
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">4/5</h5>
            <h6 class="text-color-secondary font-bold">Agrega la información de establecimiento, puede encontrar esta información en tu RUC</h6>
          `
        },
        {
          target: "#app",
          content: `
            <h5 class="font-bold">5/5</h5>
            <h6 class="text-color-secondary font-bold">Realiza pruebas enviando documentos al Sri, una vez puedas verificarlos, habilita el ambiente de producción</h6>
          `
        }
      ]
    };
  },
  computed: {},
  methods: {
    tourStepFinish() {
      const userConfigGeneral = {
        is_business_tour_completed: 1
      };
      this.$store.dispatch("userConfigGeneralUpdated", userConfigGeneral);
    }
  },
  mounted() {
    this.$tours["tour"].start();
  }
};
</script>

<style lang="scss" scoped></style>
