<template>
  <el-drawer
    title=""
    :visible.sync="onDrawerVisible"
    :with-header="false"
    @closed="onCloseDrawer"
    size="40%"
  >
    <section v-if="form" class="wrapper__business-emission-point-add p-4">
      <b-row>
        <b-col xs="12" lg="12">
          <div
            class="d-flex justify-content-center align-items-center flex-column"
          >
            <img :src="icoEmissionPoint" alt="emission point" width="48" />
            <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
              Modificar punto de emisión
            </h4>
          </div>
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item prop="code" class="mb-4">
              <slot name="label">
                <label class="font-semi-bold m-0">Punto de emisión</label>
              </slot>
              <el-input placeholder="" class="" v-model="form.code"></el-input>
            </el-form-item>

            <el-form-item prop="description" class="mb-4">
              <slot name="label">
                <label class="font-semi-bold m-0">Detalle</label>
              </slot>
              <el-input
                placeholder=""
                class=""
                v-model="form.description"
              ></el-input>
            </el-form-item>

            <b-row>
              <b-col xs="12" sm="12">
                <ButtonPrimaryComponent
                  class="center mt-4"
                  title="Guardar"
                  @onSaveClick="handleSaveClick"
                />
              </b-col>
            </b-row>
          </el-form>
        </b-col>
      </b-row>
    </section>
  </el-drawer>
</template>

<script>
import { icoEmissionPoint } from "@/services/resources";
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import Validate from "@/assets/validate";
import tradeService from "../../../services/tradeService";

export default {
  name: "BusinessStablishmentEditComponent",
  data() {
    return {
      onDrawerVisible: this.visible,
      icoEmissionPoint,
      form: null,
      rules: {
        code: [Validate.rules.required()],
        description: [Validate.rules.required()]
      }
    };
  },
  watch: {
    visible(v) {
      this.onDrawerVisible = v;
    }
  },
  methods: {
    onCloseDrawer() {
      this.onDrawerVisible = false;
      this.$emit("onCloseDrawer");
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.handleFormatData();
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await tradeService
              .updateEmissionPoints(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });

            const { success, data, message } = response.data;
            if (success) {
              this.$emit("onEmissionPointEdited", {
                getEmissionPoint: data
              });
              this.onCloseDrawer();
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    handleFormatData() {
      this.form.code = parseInt(this.form.code);
    }
  },
  components: {
    ButtonPrimaryComponent
  },
  props: {
    visible: {
      default: false
    }
  },
  mounted() {
    this.$root.$on("onEmissionPointEdit", emissionPoint => {
      this.form = {
        ...this.form,
        ...emissionPoint,
        edit: true
      };
    });
  }
};
</script>

<style lang="scss" scoped></style>
