<template>
  <section class="wrapper__business-bank-account-list">
    <h4 class="h4 font-semi-bold d-inline-block">Cuentas bancarias</h4>

    <SkeletonOneCardComponent height="60px" v-if="toggleApiRequest" />

    <template v-else>
      <template v-if="bankAccounts.length">
        <div
          v-for="(bankAccount, index) in bankAccounts"
          :key="index"
          class="bank-account-content my-4"
        >
          <b-row>
            <b-col xs="12" sm="12" md="8" lg="6" class="d-flex align-items-center">
              <!-- <p class="text-color-primary font-semi-bold m-0">Titulo</p> -->
              <strong><i class="el-icon-caret-right"></i></strong>
                {{ bankAccount.name }}
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4">
              <div class="d-flex justify-content-center">
                <button
                  class="btn md__btn-link bgt p-0 m-0"
                  @click="onClickEditBankAccount(bankAccount, index)"
                >
                  <img
                    :src="icoPencil"
                    alt="ico pencil"
                    width="45"
                    class=""
                    v-if="edit"
                  />
                </button>
                <ButtonDeleteComponent
                  :isShowButton="false"
                  @onConfirmDelete="onConfirmDeleteBankAccount(bankAccount, index)"
                  class="ml-4"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </template>
      <EmptyData v-else />
    </template>
    <LinkPlusComponent
      title="Añadir nueva cuenta"
      class="float-left"
      @onClick="onClickAddBankAccount"
      v-if="insert"
    />
    <WrapperDrawer
      :onVisible.sync="drawerBankAccountAdd"
      @onCloseDrawer="onCloseDrawerBankAccountAdd"
    >
      <BankAccountAdd @onBankAccountAdd="onBankAccountAdd" />
    </WrapperDrawer>

    <WrapperDrawer
      :onVisible.sync="drawerBankAccountEdit"
      @onCloseDrawer="onCloseDrawerBankAccountEdit"
    >
      <BankAccountEdit
        :bankAccount.sync="bankAccountEdit"
        @onBankAccountEdit="onBankAccountEdit"
      />
    </WrapperDrawer>
  </section>
</template>

<script>
import { icoPencil } from "@/services/resources";
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import ButtonDeleteComponent from "@/modules/dashboard/components/ButtonDelete";
import WrapperDrawer from "@/components/General/WrapperDrawer";
import BankAccountAdd from "./BankAccountAdd";
import BankAccountEdit from "./BankAccountEdit";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";
import EmptyData from "@/components/General/EmptyData";

import businessService from "../../services/index";

export default {
  name: "BankAccountList",
  data() {
    return {
      toggleApiRequest: false,
      icoPencil,
      bankAccounts: [],
      drawerBankAccountAdd: false,
      drawerBankAccountEdit: false,
      bankAccountEdit: null
    };
  },
  props: {
    edit: {
      default: true
    },
    insert: {
      default: true
    }
  },
  methods: {
    onClickAddBankAccount() {
      this.drawerBankAccountAdd = true;
    },
    onCloseDrawerBankAccountAdd() {
      this.drawerBankAccountAdd = false;
    },
    onCloseDrawerBankAccountEdit() {
      this.drawerBankAccountEdit = false;
    },
    onBankAccountAdd({ bankAccount }) {
      this.bankAccounts = [...[bankAccount], ...this.bankAccounts];
      this.onCloseDrawerBankAccountAdd();
    },
    onBankAccountEdit({ bankAccount }) {
      const bankAccountIndex = this.bankAccounts.find(
        (b, index) => b.id === bankAccount.id && index
      );
      this.bankAccounts[bankAccountIndex] = bankAccount;
    },
    onClickEditBankAccount(bankAccount) {
      this.bankAccountEdit = bankAccount;
      this.drawerBankAccountEdit = true;
    },
    async onConfirmDeleteBankAccount({ id: bankAccountId }, index) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await businessService
          .deleteBankAccount(bankAccountId)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.bankAccounts.splice(index, 1);
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },

    async getBankAccounts() {
      try {
        this.toggleApiRequest = true;
        let response = await businessService.getBankAccounts().finally(() => {
          this.toggleApiRequest = false;
        });
        const { data, success } = response.data;
        if (success) {
          this.bankAccounts = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    LinkPlusComponent,
    ButtonDeleteComponent,
    WrapperDrawer,
    BankAccountAdd,
    BankAccountEdit,
    SkeletonOneCardComponent,
    EmptyData
  },
  mounted() {
    this.getBankAccounts();
  }
};
</script>

<style lang="scss" scoped></style>
