<template>
  <section class="wrapper__business-stablishment-list">
    <h4 class="h4 font-semi-bold">Establecimientos</h4>
    <template v-if="apiResponse.success">
      <div
        v-for="(stablishment, index) in stablishments"
        :key="index"
        class="stablishment-content my-4 cursor-point"
        @click.stop="onStablishmentView(stablishment)"
      >
        <b-row>
          <b-col xs="12" sm="2" md="1" lg="1">
            <img :src="icoCircleStores" alt="ico stores" width="45" />
          </b-col>
          <b-col xs="12" sm="2" md="3" lg="2">
            <p class="text-color-primary font-semi-bold m-0">Código</p>
            <p>{{ stablishment.code }}</p>
          </b-col>
          <b-col xs="12" sm="4" md="4" lg="6">
            <p class="text-color-primary font-semi-bold m-0">Nombre</p>
            <p>{{ stablishment.name }}</p>
          </b-col>
          <b-col xs="12" sm="1" md="1" lg="1">
            <button
              class="btn md__btn-link bgt p-0 m-0 float-right"
              title="Establecimiento por defecto"
              @click.stop="setStablishmentDefault(stablishment)"
            >
              <img
                :src="[
                  stablishment.is_default == 1 ? iconCheckOn : iconCheckOff
                ]"
                alt="ico check"
                width="45"
              />
            </button>
          </b-col>
          <b-col xs="12" sm="1" md="1" lg="1">
            <button
              class="btn md__btn-link bgt p-0 m-0 float-right"
              @click.stop="onClickEditStablishment(stablishment)"
            >
              <img :src="icoPencil" alt="ico pencil" width="45" class="" />
            </button>
          </b-col>
        </b-row>
      </div>
    </template>

    <SkeletonOneCardComponent height="60px" v-else />

    <b-row class="mt-4">
      <b-col>
        <LinkPlusComponent
          title="Añadir nuevo establecimiento"
          @onClick="onClickAddStablishment"
        />
      </b-col>
    </b-row>

    <BusinessStablishmentAddComponent
      @onCloseDrawer="onCloseStablishmentAddDrawer"
      :visible.sync="visibleAddStablishment"
    />

    <BusinessStablishmentEditComponent
      @onCloseDrawer="onCloseStablishmentEditDrawer"
      :visible.sync="visibleEditStablishment"
    />
  </section>
</template>

<script>
import {
  icoCircleStores,
  icoPencil,
  iconCheckOn,
  iconCheckOff
} from "@/services/resources";
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import BusinessStablishmentAddComponent from "./StablishmentAdd";
import BusinessStablishmentEditComponent from "./StablishmentEdit";
import tradeService from "../../../services/tradeService";
import SkeletonOneCardComponent from "@/components/Animations/Loading/SkeletonOneCard";

export default {
  name: "BusinessStablishmentListComponent",
  data() {
    return {
      icoCircleStores,
      icoPencil,
      iconCheckOn,
      iconCheckOff,
      apiResponse: {
        success: false
      },
      visibleAddStablishment: false,
      visibleEditStablishment: false,
      stablishments: []
    };
  },
  methods: {
    toggleApiResponse(property, value) {
      this.apiResponse[property] = value;
    },
    onClickAddStablishment() {
      this.visibleAddStablishment = true;
    },
    onCloseStablishmentAddDrawer() {
      this.visibleAddStablishment = false;
    },

    onClickEditStablishment(stablishment) {
      this.$root.$emit("onStablishmentEdit", stablishment);
      this.visibleEditStablishment = true;
    },
    onCloseStablishmentEditDrawer() {
      this.visibleEditStablishment = false;
    },
    onStablishmentView(stablishment) {
      this.$emit("onStablishmentView", stablishment);
    },
    async setStablishmentDefault(stablishment) {
      try {
        let response = await tradeService.setStablishmentDefault(stablishment);
        if (response.data) {
          this.toggleApiResponse("success", true);
          this.getStablishments();
        }
      } catch (error) {
        return false;
      }
    },
    async getStablishments() {
      try {
        let response = await tradeService.getStablishments();
        if (response.data.data) {
          this.stablishments = response.data.data;
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    LinkPlusComponent,
    BusinessStablishmentAddComponent,
    BusinessStablishmentEditComponent,
    SkeletonOneCardComponent
  },
  mounted() {
    this.getStablishments();

    this.$root.$on("onStablishmentAdd", () => {
      this.getStablishments();
    });
  }
};
</script>

<style lang="scss" scoped></style>
