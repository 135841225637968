<template>
  <div>
    <slot name="label">
      <label class="font-semi-bold m-0 d-block">{{ title }}</label>
    </slot>

    <el-select
      filterable
      v-model="emissionPointId"
      :placeholder="placeholder"
      @change="onChangeSelect"
    >
      <el-option
        v-for="point in emissionPoints"
        :key="point.id"
        :label="
          `${point.user_business_establishment.name} (${point.user_business_establishment.code} - ${point.code})`
        "
        :value="point.id"
      ></el-option>
    </el-select>
  </div>
</template>

<script>
import tradeService from "../profile/services/tradeService";

export default {
  name: "SelectEmissionPointsComponent",
  data() {
    return {
      emissionPoints: [],
      emissionPointId: this.setEmissionPointId
    };
  },
  methods: {
    onChangeSelect() {
      this.$emit("onChangeSelect", {
        emissionPointId: this.emissionPointId
      });
    },
    async getEmissionPoints() {
      await tradeService.getEmissionPoints().then(response => {
        if (response.data.data) this.emissionPoints = response.data.data;
      });
    }
  },
  props: {
    title: {
      default: "Seleccionar Negocio"
    },
    placeholder: {
      default: "Seleccionar"
    },
    setEmissionPointId: {
      default: null
    }
  },
  mounted() {
    this.getEmissionPoints();
  }
};
</script>

<style lang="sass" scoped></style>
