export const PeopleNumbers = [
  {
    value: 1,
    label: "1"
  },
  {
    value: 2,
    label: "2-5"
  },
  {
    value: 4,
    label: "6-10"
  },
  {
    value: 5,
    label: "11-50"
  },
  {
    value: 6,
    label: "50+"
  }
];
