<template>
  <div>
    <section class="wrapper__billing-p12">
      <h4 class="h4 font-bold">Configuración de Firma Electrónica</h4>

      <el-form :model="form" ref="form" :rules="rules">
        <div class="upload-p12 my-4 border-radius md__border p-4">
          <el-form-item prop="file" class="">
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <slot name="label">
                  <label class="font-semi-bold m-0">Firma electrónica</label>
                </slot>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <el-upload
                  action="#"
                  :auto-upload="false"
                  :limit="1"
                  ref="file"
                  accept=".p12"
                  :on-change="handleChangeFile"
                  class="float-right"
                >
                  <el-button size="small" type="default"
                    >Seleccionar Archivo</el-button
                  >
                </el-upload>
              </b-col>
            </b-row>
            <b-row v-if="getBusinessSriFile && !form.file">
              <b-col>
                <p>
                  <img :src="icoCheckMarkSuccess" alt="" width="18px" />
                  <strong>Archivo de firma cargado en la plataforma</strong>
                </p>
              </b-col>
            </b-row>
          </el-form-item>
        </div>

        <el-form-item prop="password">
          <slot name="label">
            <label class="font-semi-bold m-0">Contraseña de firma</label>
          </slot>
          <el-input
            show-password
            type="password"
            name="password"
            :disabled="togglePassword"
            v-model="form.password"
          ></el-input>
        </el-form-item>

        <ButtonPrimaryComponent
          title="Guardar"
          class="mt-4 float-right"
          @onSaveClick="handleSaveClick"
        />
      </el-form>
    </section>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import billingsService from "../services/billingsService";

import Validate from "@/assets/validate";
import { icoCheckMarkSuccess } from "@/services/resources";

export default {
  name: "SignatureAddComponent",
  data() {
    return {
      icoCheckMarkSuccess,
      formData: new FormData(),
      form: {
        password: this.getBusinessSriPassword,
        file: null
      },
      rules: {
        password: [Validate.rules.required()],
        file: [Validate.rules.required()]
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    getBusinessSriFile() {
      return this.getUser.user_business.business_sri_file;
    },
    getBusinessSriPassword() {
      return this.getUser.user_business.business_sri_password;
    },
    togglePassword() {
      return this.form.file ? false : true;
    }
  },
  methods: {
    async handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { file, password } = this.form;
          try {
            this.$store.dispatch("toggleRequestLoading");
            this.formData.append("password", password);
            this.formData.append("business_id", this.getUser.user_business.id);
            if (file && file.raw) {
              this.formData.append("file", file.raw);
            }
            let response = await billingsService
              .uploadSign(this.formData, {
                header: {
                  "Content-Type": "multipart/form-data"
                }
              })
              .finally(() => this.$store.dispatch("toggleRequestLoading"));
            const { success, message, data } = response.data;

            if (success) {
              this.$store.dispatch("userBusinessUpdated", data);
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    handleChangeFile(file) {
      this.form.file = file;
    }
  },
  components: {
    ButtonPrimaryComponent
  },
  mounted() {
    this.form.password = this.getBusinessSriPassword;
  }
};
</script>

<style lang="scss" scoped>
.wrapper__billing-p12 {
  // max-width: 720px;
  // display: block;
  // margin: auto;
}
</style>
