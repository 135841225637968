<template>
  <div>
    <TopBarTitleComponent title="Negocio" :showBackIcon="true">
      <template slot="right-area">
        <div class="d-flex justify-content-end align-items-center">
          <ButtonDeleteComponent
            class="mr-4"
            @onConfirmDelete="onConfirmDelete"
            v-if="actionForm == 'editar'"
          />
        </div>
      </template>
    </TopBarTitleComponent>
    <section class="">
      <b-container fluid>
        <MessageComponent
          :show="businessStart"
          title="¡Has creado un nuevo negocio!"
          subtitle="Configura tu nueva cuenta de negocio y empieza a administrarlo ¡ahora!"
          btnTitle="Comenzar"
          :routePage="{
            path: 'negocio',
            query: { action: 'nuevo' }
          }"
        />
        <b-row>
          <b-col sm="12" md="12" lg="12">
            <div class="box-shadow border-radius p-3 mb-3 bg-color-write">
              <el-tabs v-model="activeTabName">
                <el-tab-pane
                  label="Perfil de empresa"
                  name="business"
                  class="p-4"
                >
                  <div class="bg-color-write" v-if="user.user_business">
                    <b-row>
                      <b-col sm="12" md="12" lg="12">
                        <div
                          class="image"
                          :style="[
                            user.user_business.business_logo
                              ? styleProfileImage
                              : ''
                          ]"
                        >
                          <FileUploadComponent
                            title="Selecciona logo empresa"
                            @fileBase64="file => onFileBase64BusinessLogo(file)"
                            :preview="business_logo"
                            :showFileList="false"
                            className="image-profile mt-4"
                          />
                        </div>
                      </b-col>
                    </b-row>
                    <h4 class="h4 font-semi-bold mt-3 mb-4">
                      Datos de la empresa
                    </h4>
                    <el-form
                      :model="user.user_business"
                      :rules="rules"
                      ref="businessForm"
                    >
                      <b-row>
                        <b-col sm="12" md="12" lg="12">
                          <label class="font-semi-bold m-0">Razón Social</label>
                          <el-form-item prop="social_reason" class="mb-4">
                            <input
                              class="form-control input-profile"
                              v-model="user.user_business.social_reason"
                              placeholder=""
                            />
                          </el-form-item>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="6" lg="6">
                          <b-row>
                            <b-col sm="12">
                              <label class="font-semi-bold m-0"
                                >Nombre Comercial</label
                              >
                              <el-form-item prop="business_name" class="mb-4">
                                <input
                                  class="form-control input-profile"
                                  v-model="user.user_business.business_name"
                                  placeholder=""
                                />
                              </el-form-item>
                              <el-form-item prop="business_id" class="mb-4">
                                <input
                                  type="hidden"
                                  class="form-control input-profile"
                                  v-model="user.user_business.id"
                                  placeholder=""
                                />
                              </el-form-item>
                              <label class="font-semi-bold m-0">Teléfono</label>
                              <input
                                type="text"
                                class="form-control input-profile mb-3"
                                v-model="user.user_business.business_phone"
                              />

                              <label class="font-semi-bold m-0"
                                >Cantidad personas</label
                              >

                              <el-form-item prop="people_number" class="mb-4">
                                <el-select
                                  class="el-info-profile"
                                  placeholder="Seleccionar"
                                  v-model="user.user_business.people_number"
                                >
                                  <el-option
                                    v-for="peopleNumber in peopleNumbers"
                                    :key="peopleNumber.value"
                                    :label="peopleNumber.label"
                                    v-model="peopleNumber.value"
                                  ></el-option>
                                </el-select>
                              </el-form-item>

                              <label class="font-semi-bold m-0"
                                >Dirección</label
                              >
                              <el-form-item
                                prop="business_address"
                                class="mb-4"
                              >
                                <input
                                  class="form-control input-profile"
                                  v-model="user.user_business.business_address"
                                  placeholder=""
                                />
                              </el-form-item>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="12" md="6" lg="6">
                          <b-row>
                            <b-col sm="12">
                              <label class="font-semi-bold m-0">RUC</label>
                              <el-form-item prop="business_ruc" class="mb-4">
                                <input
                                  class="form-control input-profile"
                                  v-model="user.user_business.business_ruc"
                                  placeholder=""
                                />
                              </el-form-item>
                              <label class="font-semi-bold m-0">Correo</label>
                              <el-form-item prop="business_email" class="mb-4">
                                <input
                                  class="form-control input-profile"
                                  v-model="user.user_business.business_email"
                                  placeholder=""
                                />
                              </el-form-item>

                              <label class="font-semi-bold m-0"
                                >Categoría</label
                              >
                              <el-form-item prop="category_id" class="mb-4">
                                <el-select
                                  placeholder="Seleccionar"
                                  class="el-info-profile"
                                  v-model="user.user_business.category_id"
                                >
                                  <el-option
                                    v-for="category in categories"
                                    :key="category.id"
                                    :label="category.name"
                                    v-model="category.id"
                                  ></el-option>
                                </el-select>
                              </el-form-item>

                              <label class="font-semi-bold m-0">Ciudad</label>

                              <el-select
                                filterable
                                placeholder="Seleccionar"
                                class="el-info-profile mb-3"
                                v-model="user.user_business.city_id"
                              >
                                <el-option
                                  v-for="category in cities"
                                  :key="category.id"
                                  :label="category.name"
                                  v-model="category.id"
                                ></el-option>
                              </el-select>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="6" lg="6" class="mb-4">
                          <h4 class="h4 my-3">Perfil tributario</h4>
                        </b-col>
                      </b-row>
                      <b-row class="mb-4">
                        <b-col sm="12" md="6" lg="6">
                          <div class="">
                            <el-form-item
                              ><el-checkbox
                                class="d-block"
                                v-model="
                                  user.user_business.accounting_obligation
                                "
                                >Obligación de contabilidad
                              </el-checkbox></el-form-item
                            >
                          </div>

                          <div class="">
                            <el-form-item
                              ><el-checkbox
                                class="d-block"
                                v-model="
                                  user.user_business.microenterprise_obligation
                                "
                                >Contribuyente Régimen RIMPE</el-checkbox
                              ></el-form-item
                            >
                          </div>
                          <div class="">
                            <el-form-item
                              ><el-checkbox
                                class="d-block"
                                v-model="
                                  user.user_business.popular_business_obligation
                                "
                                >Contribuyente Negocio Popular - Régimen
                                RIMPE</el-checkbox
                              ></el-form-item
                            >
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="12" md="6" lg="6">
                          <label class="font-semi-bold m-0"
                            >Contribuyente Especial</label
                          >
                          <el-form-item
                            prop="special_taxpayer_number"
                            class="mb-4"
                          >
                            <input
                              class="form-control input-profile mb-3"
                              maxlength="3"
                              v-model="
                                user.user_business.special_taxpayer_number
                              "
                              placeholder=""
                            />
                          </el-form-item>
                        </b-col>
                      </b-row>
                    </el-form>
                    <b-row class="mt-4">
                      <b-col>
                        <ButtonPrimaryComponent
                          title="Guardar"
                          class="float-right"
                          @onSaveClick="handleSaveBusiness"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </el-tab-pane>
                <el-tab-pane
                  label="Facturación Electrónica"
                  name="signature"
                  class="p-4"
                  v-if="actionForm == 'editar'"
                >
                  <b-row>
                    <b-col xs="12" sm="3" md="3" lg="3">
                      <div class="list-tabs-left">
                        <el-tabs
                          v-model="activeTabsSignature"
                          :tab-position="tabsPositionLeft"
                          style="height: 200px;"
                          @tab-click="handleTabsSignature"
                        >
                          <el-tab-pane
                            label="Configuración de Firma"
                            name="configSignature"
                          ></el-tab-pane>
                          <el-tab-pane
                            label="Configuración General"
                            name="configGeneral"
                          ></el-tab-pane>
                        </el-tabs>
                      </div>
                    </b-col>
                    <b-col xs="12" sm="9" md="9" lg="9">
                      <SignatureAddComponent
                        v-if="activeTabsSignature === 'configSignature'"
                      />
                      <DocumentSequenceAddComponent
                        v-if="activeTabsSignature === 'configGeneral'"
                        class="mt-2"
                      />
                    </b-col>
                  </b-row>
                </el-tab-pane>
                <el-tab-pane
                  label="Establecimientos"
                  name="stablishment"
                  class="p-4"
                  v-if="actionForm == 'editar'"
                >
                  <TransitionFadeComponent>
                    <BusinessStablishmentListComponent
                      v-if="!toogleStablishment"
                      @onStablishmentView="onStablishmentView"
                    />

                    <BusinessStablishmentViewComponent
                      v-else
                      :stablishment="establishmentSelected"
                      @onStablishmentListBack="onStablishmentView"
                    />
                  </TransitionFadeComponent>
                </el-tab-pane>

                <el-tab-pane
                  label="Integraciones"
                  name="integrations"
                  class="p-4"
                  v-if="actionForm == 'editar'"
                >
                  <b-row>
                    <b-col xs="12" sm="3" md="3" lg="3">
                      <div class="list-tabs-left">
                        <el-tabs
                          v-model="activeTabsIntegrations"
                          :tab-position="tabsPositionLeft"
                          style="height: 200px;"
                          @tab-click="handleTabsIntegrations"
                        >
                          <el-tab-pane
                            label="Woocommerce"
                            name="integWoocommerce"
                          ></el-tab-pane>
                        </el-tabs>
                      </div>
                    </b-col>
                    <b-col xs="12" sm="9" md="9" lg="9">
                      <WoocommerceAdd
                        v-show="activeTabsIntegrations === 'integWoocommerce'"
                      />
                    </b-col>
                  </b-row>
                </el-tab-pane>

                <el-tab-pane label="Cuentas bancarias" name="earnings" class="p-4">
                  <b-row>
                    <!-- <b-col xs="12" sm="3" md="3" lg="3">
                      <div class="list-tabs-left">
                        <el-tabs
                          v-model="activeTabsEarnings"
                          :tab-position="tabsPositionLeft"
                          style="height: 200px;"
                          @tab-click="handleTabsEarnings"
                        >
                          <el-tab-pane
                            label="Cuentas de Bancos"
                            name="integBankAccount"
                          ></el-tab-pane>
                          <el-tab-pane
                            label="Formas de Pagos"
                            name="integPayforms"
                          ></el-tab-pane>
                        </el-tabs>
                      </div>
                    </b-col>-->

                    <b-col>
                      <BankAccountList
                        v-show="activeTabsEarnings === 'integBankAccount'"
                      />
                      <!-- <FormsPayCofig
                        v-show="activeTabsEarnings === 'integPayforms'"
                      /> -->
                    </b-col>
                  </b-row>
                </el-tab-pane>
              </el-tabs>
            </div>
          </b-col>
        </b-row>
        <MessageComponent
          title_main="¡Felicidades!"
          :show="businessCreated"
          title="La información de tu empresa fue guardada exitosamente"
          subtitle="Configura tu nueva cuenta de negocio y empieza a administrarlo ¡ahora!"
          btnTitle="Comenzar"
          :routePage="{
            name: 'dashboard'
          }"
        />
      </b-container>
    </section>
    <WrapperBusinessTourComponent />
  </div>
</template>

<script>
import FileUploadComponent from "../../../../components/FileUpload";
import TransitionFadeComponent from "@/components/Transitions/Fade";
import TopBarTitleComponent from "../../../../components/TopBarTitle";
import CategoriesServices from "@/services/CategoriesServices";
import CitiesServices from "@/services/CitiesServices";
import ButtonPrimaryComponent from "../../../../components/ButtonPrimary";
import BusinessStablishmentListComponent from "../components/StablishmentList";
import BusinessStablishmentViewComponent from "../components/StablishmentView";
import SignatureAddComponent from "../../../../billings/components/SignatureAdd";
import DocumentSequenceAddComponent from "../../../../billings/components/DocumentSequenceAdd";
import WrapperBusinessTourComponent from "../components/WrapperTour";
import WoocommerceAdd from "../components/WoocommerceAdd";
import BankAccountList from "../components/BankAccount/BankAccountList";
//import FormsPayCofig from "../components/FormsPay/FomsPayConfig.vue";

import { mapGetters } from "vuex";
import { PeopleNumbers } from "@/services/PeopleNumbers";
import { bgProfile } from "@/services/resources";
import Validate from "@/assets/validate";
import MessageComponent from "@/modules/dashboard/components/Message";
import ButtonDeleteComponent from "../../../../components/ButtonDelete";
import businessService from "../services/index";

export default {
  name: "ProfileBusinessMainPage",
  data: () => ({
    activeTabName: "business",
    activeTabsSignature: "configSignature",
    activeTabsIntegrations: "integWoocommerce",
    activeTabsEarnings: "integBankAccount",
    tabsPositionLeft: "left",
    categories: [],
    peopleNumbers: PeopleNumbers,
    cities: [],
    user: "",
    toogleStablishment: false,
    apiResponse: {
      message: null
    },
    establishmentSelected: null,
    rules: {
      business_name: [Validate.rules.required()],
      business_ruc: [Validate.rules.required()],
      business_email: [
        { ...Validate.rules.required(), ...Validate.rules.email() }
      ],
      business_address: [Validate.rules.required()],
      business_logo: [Validate.rules.required()],
      people_number: [Validate.rules.required("blur")],
      category_id: [Validate.rules.required("blur")],
      social_reason: [Validate.rules.required()]
    },
    businessCreated: false,
    businessStart: false,
    business_logo: null,
    actionForm: null
  }),

  watch: {
    getUser(value) {
      this.user.user_business = value.user_business;
    },

    user(value) {
      this.user = value;
    },

    businessCreated(value) {
      this.businessCreated = value;
    },

    businessStart(value) {
      this.businessStart = value;
    },

    $route() {
      this.$router.go();
    },

    actionForm(value) {
      this.actionForm = value;
    }
  },
  computed: {
    ...mapGetters({
      getUser: "getUser"
    }),

    getUser() {
      return this.$store.getters.getUser;
    },

    styleProfileImage() {
      return {
        background: `url(${bgProfile}) no-repeat center center`,
        backgroundSize: "cover",
        position: "relative",
        height: "140px"
      };
    },

    getSyncRules() {
      return Validate;
    }
  },

  methods: {
    onFileBase64BusinessLogo(file) {
      this.user.user_business.business_logo = file;
    },

    updateForm() {
      this.actionForm = this.$route.query.action;
      if (this.$route.query.action == "nuevo") {
        this.user = {
          user_business: {
            business_id: null,
            business_name: null,
            business_ruc: null,
            business_phone: null,
            business_email: null,
            business_address: null,
            business_logo: "",
            people_number: null,
            category_id: null,
            city_id: null,
            social_reason: null,
            special_taxpayer_number: null,
            accounting_obligation: false,
            microenterprise_obligation: false,
            popular_business_obligation: false
          }
        };
        this.businessStart = true;
        /*if (this.getUser.businesses.length > 1) {
          this.businessStart = false;
        }*/
        this.$forceUpdate();
      } else {
        this.businessStart = false;
        this.user.user_business = this.getUser.user_business;
        this.user.user_business.accounting_obligation =
          this.user.user_business.accounting_obligation == 1 ? true : false;
        this.user.user_business.microenterprise_obligation =
          this.user.user_business.microenterprise_obligation == 1
            ? true
            : false;
        this.user.user_business.popular_business_obligation =
          this.user.user_business.popular_business_obligation == 1
            ? true
            : false;
      }
    },

    handleTabsSignaturePosition(tab) {
      this.activeTtabsSignature = tab.name;
    },

    handleTabsSignature(tab) {
      this.activeTabsSignature = tab.name;
    },

    handleTabsIntegrations(tab) {
      this.activeTabsIntegrations = tab.name;
    },

    handleTabsEarnings(tab) {
      this.activeTabsEarnings = tab.name;
    },

    async handleSaveBusiness() {
      const { name, last_name, phone, image } = this.user;

      const {
        id,
        business_name,
        business_id,
        business_ruc,
        business_phone,
        business_email,
        business_address,
        business_logo,
        people_number,
        category_id,
        city_id,
        social_reason,
        special_taxpayer_number,
        accounting_obligation,
        microenterprise_obligation,
        popular_business_obligation
      } = this.user.user_business;

      var data = {
        id,
        name,
        last_name,
        phone,
        image,
        business_name,
        business_id,
        business_ruc,
        business_phone,
        business_email,
        business_address,
        business_logo,
        people_number,
        category_id,
        city_id,
        social_reason,
        special_taxpayer_number,
        accounting_obligation,
        microenterprise_obligation,
        popular_business_obligation
      };
      try {
        this.$refs.businessForm.validate(async valid => {
          if (valid) {
            try {
              let response = await this.$store.dispatch("storeBusiness", data);
              const { success } = response.data;
              if (success) {
                if (this.$route.query.action == "nuevo")
                  this.businessCreated = true;
                else {
                  this.$notifications.success({});
                  this.user.user_business.accounting_obligation =
                    this.user.user_business.accounting_obligation == 1
                      ? true
                      : false;
                  this.user.user_business.microenterprise_obligation =
                    this.user.user_business.microenterprise_obligation == 1
                      ? true
                      : false;
                  this.user.user_business.popular_business_obligation =
                    this.user.user_business.popular_business_obligation == 1
                      ? true
                      : false;
                  // this.$refs.businessForm.resetFields();
                }
              }
            } catch (error) {
              return false;
            }
          }
        });
      } catch (error) {
        return false;
      }
    },

    async getCategories() {
      await CategoriesServices.getCategories().then(response => {
        if (response.data.success) this.categories = response.data.categories;
      });
    },

    async getCities() {
      await CitiesServices.getCities().then(response => {
        if (response.data.success) this.cities = response.data.cities;
      });
    },

    onStablishmentView(stablishment) {
      this.establishmentSelected = stablishment;
      this.toogleStablishment = !this.toogleStablishment;
    },

    async onConfirmDelete() {
      if (this.getUser.businesses.length > 1) {
        this.$store.dispatch("toggleRequestLoading");
        try {
          let response = await businessService
            .deleteBusiness(this.user.user_business.id)
            .finally(() => {
              this.$store.dispatch("toggleRequestLoading");
            });
          const { success, data } = response.data;
          if (success) {
            this.$notifications.success({});
            this.$refs.businessForm.resetFields();
            this.$store.dispatch("userUpdate", data);
          }
        } catch (error) {
          return false;
        }
      } else {
        this.$notifications.warning({
          message: "No puede eliminar el único negocio registrado."
        });
      }
    }
  },

  components: {
    TransitionFadeComponent,
    TopBarTitleComponent,
    FileUploadComponent,
    ButtonPrimaryComponent,
    BusinessStablishmentListComponent,
    BusinessStablishmentViewComponent,
    SignatureAddComponent,
    DocumentSequenceAddComponent,
    WrapperBusinessTourComponent,
    MessageComponent,
    WoocommerceAdd,
    ButtonDeleteComponent,
    BankAccountList
    //FormsPayCofig
  },

  beforeMount() {
    this.user = this.getUser;
    if (this.$route.query.action == "nuevo") {
      this.business_logo = null;
    } else {
      this.business_logo = this.user.user_business.business_logo;
    }
  },

  mounted() {
    this.getCategories();
    this.getCities();
    this.updateForm();
  }
};
</script>

<style scoped lang="scss">
.image {
  display: flex !important;
  justify-content: center;
  align-items: baseline;
  height: 100%;
}

#image-profile {
  width: 100px;
}

#image-business {
  width: 100%;
  height: auto;
}
</style>
