<template>
  <div>
    <section class="wrapper__billing-p12">
      <h4 class="h4 font-bold">Configuración General</h4>
      <p>
        Establece la cofiguración de secuencia de los documentos, como el
        entorno de ejecución del firmado
      </p>
      <el-form :model="form" :rules="rules" ref="form">
        <b-row>
          <b-col xs="12" sm="12" md="12">
            <el-form-item prop="woocommerce_emision_point" class="mb-4">
              <SelectEmissionPointsComponent
                @onChangeSelect="onChangeSelectEmissionPoints"
              />
            </el-form-item>
          </b-col>
        </b-row>
        <el-form-item prop="debit_note_sequence">
          <slot name="label">
            <label class="font-semi-bold m-0">Modo de firmado</label>
          </slot>

          <div class="">
            <div class="d-flex align-items-center">
              <el-switch
                v-model="form.environment"
                active-color="#3fdbbf"
                inactive-color="#989aae"
              >
              </el-switch>
              <span class="ml-4 text-color-primary font-bold"
                >Habilitar modo producción</span
              >
            </div>
            <!-- <small class="text-color-black"
              ><em
                >(Si no se encuentra habilitado por defecto el modo será de
                desarrollo.)</em
              ></small
            > -->
          </div>
        </el-form-item>

        <template v-if="form.environment">
          <p class="font-semi-bold text-color-primary">
            Secuencias de documentos
          </p>
          <el-form-item prop="invoice_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Factura</label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.invoice_sequence"
            ></el-input>
          </el-form-item>

          <el-form-item prop="credit_note_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Nota crédito</label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.credit_note_sequence"
            ></el-input>
          </el-form-item>

          <el-form-item prop="debit_note_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Nota débito</label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.debit_note_sequence"
            ></el-input>
          </el-form-item>

          <el-form-item prop="liquidation_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Liquidación de Compra</label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.liquidation_sequence"
            ></el-input>
          </el-form-item>

          <el-form-item prop="guide_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Guía de remisión </label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.guide_sequence"
            ></el-input>
          </el-form-item>

          <el-form-item prop="retention_sequence">
            <slot name="label">
              <label class="font-semi-bold m-0">Retenciones </label>
            </slot>
            <el-input
              type="text"
              name=""
              v-model="form.retention_sequence"
            ></el-input>
          </el-form-item>
        </template>
        <ButtonPrimaryComponent
          title="Guardar"
          class="mt-4 float-right"
          @onSaveClick="handleSaveClick"
        />
      </el-form>
    </section>
  </div>
</template>

<script>
import ButtonPrimaryComponent from "../../components/ButtonPrimary";
import billingsService from "../services/billingsService";
import SelectEmissionPointsComponent from "../../components/SelectNegocios";
// import Validate from "@/assets/validate";

export default {
  name: "DocumentSequenceAddComponent",
  data() {
    return {
      formData: new FormData(),
      form: {
        user_business_e_p_id: null,
        invoice_sequence: null,
        credit_note_sequence: 0,
        debit_note_sequence: 0,
        guide_sequence: 0,
        retention_sequence: 0,
        liquidation_sequence: 0,
        environment: 0
      },
      rules: {}
    };
  },
  watch: {
    "form.environment"(val) {
      this.form.environment = this.$options.filters.formatBoolean(val);
    }
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },

  methods: {
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.resetForm(emissionPointId);
      this.getConfigDocumentSequences(emissionPointId);
      this.form.woocommerce_emision_point = emissionPointId;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.$store.dispatch("toggleRequestLoading");

            let response = await billingsService
              .updateConfigDocumentSequences(this.form)
              .finally(() => this.$store.dispatch("toggleRequestLoading"));

            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async getConfigDocumentSequences(id = "") {
      try {
        let response = await billingsService.getConfigDocumentSequences(id);
        const { data, success } = response.data;
        if (success) {
          this.form = {
            ...this.form,
            ...data
          };
        }
      } catch (error) {
        return false;
      }
    },
    resetForm() {
      this.form = {
        invoice_sequence: null,
        credit_note_sequence: 0,
        debit_note_sequence: 0,
        guide_sequence: 0,
        retention_sequence: 0,
        liquidation_sequence: 0,
        environment: 0
      };
    }
  },
  components: {
    ButtonPrimaryComponent,
    SelectEmissionPointsComponent
  },
  mounted() {
    this.getConfigDocumentSequences();
  }
};
</script>

<style lang="scss" scoped></style>
