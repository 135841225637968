<template>
  <el-dialog
    width="40%"
    title=""
    :visible.sync="outerVisible"
    @close="onCloseDialog"
    class="dialog-celebration"
  >
    <img :src="imgCelebration" alt="Success account" id="success-account" />
    <section class="step step-0">
      <h3 class="h3 text-center font-semi-bold text-color-primary">
        <span class="text-color-secondary">{{ title_main }}</span>
        <br />{{ title }}
      </h3>
      <p>
        {{ subtitle }}
      </p>
    </section>
    <section class="buttons-steps">
      <button @click="next" class="btn md__btn-primary">
        {{ btnTitle }}
      </button>
    </section>
  </el-dialog>
</template>

<script>
import { imgCelebration } from "@/services/resources";

export default {
  name: "MessageComponent",
  data: function() {
    return {
      imgCelebration,
      outerVisible: this.show
    };
  },
  watch: {
    show(value) {
      this.outerVisible = value;
    },
    routePage(value) {
      this.routePage = value;
    }
  },
  methods: {
    next() {
      this.outerVisible = false;
      this.$router.push(this.routePage).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    onCloseDialog() {
      this.outerVisible = false;
    }
  },
  props: {
    title_main: {
      type: String
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    btnTitle: {
      default: "Finalizar",
      type: String
    },
    routePage: {
      type: Object
    },
    show: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="sass">
.dialog-celebration
  #success-account
    width: 160px;
    margin: 2rem auto;
    display: block;

  .el-dialog
    border-radius: 0.3rem;
    h3
      word-break: break-word;
    p
      margin: 1rem 0;
      text-align: center;
      word-break: break-word;
  .el-dialog__body
    padding-bottom: 0px !important;

    .el-steps
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 3rem;
      .el-step__head
        display: none;
        &.is-process
          border-color: #9a0252;
          .el-step__icon
            background: #9a0252;
        &.is-success, &.is-wait
          border-color: #C0C4CC;
          .el-step__icon
            background: #C0C4CC;
        .el-step__line
          display: none;
        .el-step__icon
          width: 20px;
          height: 20px;
          margin: 0 .5rem;
      .el-step__icon-inner
        display: none;
  .buttons-steps
    display: flex;
    justify-content: space-evenly;
    max-width: 300px;
    margin: 2rem auto;
</style>
