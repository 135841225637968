<template>
  <button :disabled="disabled" :class="className" @click.prevent="handleClick">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "ButtonComponent",

  methods: {
    handleClick() {
      this.$emit("onSaveClick");
    }
  },
  props: {
    title: {
      default: ""
    },
    disabled: {
      default: false
    },
    className: {
      default: "btn md__btn-primary"
    }
  }
};
</script>

<style></style>
