<template>
  <TransitionBounceComponent>
    <BusinessTourComponent v-if="showTour && isBusinessTourCompleted" />
  </TransitionBounceComponent>
</template>

<script>
import TransitionBounceComponent from "@/components/Transitions/Bounce";
import BusinessTourComponent from "./Tour";

export default {
  name: "WrapperBusinessTourComponent",
  data() {
    return {
      showTour: false
    };
  },
  computed: {
    isBusinessTourCompleted() {
      const { user_config_general } = this.$store.getters.getUser;
      return (
        user_config_general && !user_config_general.is_business_tour_completed
      );
    }
  },
  methods: {},
  components: {
    TransitionBounceComponent,
    BusinessTourComponent
  },
  mounted() {
    setTimeout(() => {
      if (this.isBusinessTourCompleted) this.showTour = true;
    }, 1000);
  }
};
</script>

<style lang="scss" scoped></style>
