<template>
  <section class="wrapper__business-emision-point-list">
    <div
      v-for="(emissionPoint, index) in emissionPoints"
      :key="index"
      class="emision-point-content my-4"
    >
      <b-row>
        <b-col xs="12" sm="6" md="1" lg="1">
          <img :src="icoCircleStores" alt="ico stores" width="45" />
        </b-col>
        <b-col xs="12" sm="6" md="3" lg="2">
          <p class="text-color-primary font-semi-bold m-0">Código</p>
          <p>{{ emissionPoint.code }}</p>
        </b-col>
        <b-col xs="12" sm="6" md="3" lg="4">
          <p class="text-color-primary font-semi-bold m-0">Detalle</p>
          <p>{{ emissionPoint.description }}</p>
        </b-col>
        <b-col xs="12" sm="6" md="3" lg="3">
          <button
            class="btn md__btn-link bgt p-0 m-0 float-right"
            title="Establecimiento por defecto"
            @click.stop="setEmissionPointDefault(index)"
          >
            <img
              :src="[emissionPoint.is_default ? iconCheckOn : iconCheckOff]"
              alt="ico check"
              width="45"
            />
          </button>
        </b-col>
        <b-col xs="12" sm="6" md="2" lg="2">
          <ButtonDeleteComponent
            class="float-right ml-2"
            :isShowButton="false"
            @onConfirmDelete="
              onConfirmDeleteEmissionPoint(emissionPoint, index)
            "
          />
          <button
            class="btn md__btn-link bgt p-0 m-0 float-right"
            @click="onClickEditEmissionPoint(emissionPoint, index)"
          >
            <img :src="icoPencil" alt="ico pencil" width="45" class="" />
          </button>
        </b-col>
      </b-row>
    </div>

    <b-row class="mt-4">
      <b-col>
        <LinkPlusComponent
          title="Añadir nuevo punto de emisión"
          @onClick="onClickAddEmissionPoint"
        />
      </b-col>
    </b-row>

    <BusinessEmissionPointAddComponent
      @onCloseDrawer="onCloseEmissionPointAddDrawer"
      :visible.sync="visibleAddEmissionPoint"
      :establishmentId="establishmentId"
      @onEmissionPointAdded="onEmissionPointAdded"
    />

    <BusinessEmissionPointEditComponent
      @onCloseDrawer="onCloseEmissionPointEditDrawer"
      :visible.sync="visibleEditEmissionPoint"
      @onEmissionPointEdited="onEmissionPointEdited"
    />
  </section>
</template>

<script>
import {
  icoCircleStores,
  icoPencil,
  iconCheckOn,
  iconCheckOff
} from "@/services/resources";
import LinkPlusComponent from "@/modules/dashboard/components/LinkPlus";
import BusinessEmissionPointAddComponent from "./EmissionPointAdd";
import BusinessEmissionPointEditComponent from "./EmissionPointEdit";
import ButtonDeleteComponent from "../../../../components/ButtonDelete";
import tradeService from "../../../services/tradeService";

export default {
  name: "BusinessEmissionPointListComponent",
  data() {
    return {
      icoCircleStores,
      icoPencil,
      visibleAddEmissionPoint: false,
      visibleEditEmissionPoint: false,
      emissionPoints: this.setEmissionPoints,
      emissionPointIndexActived: null,
      iconCheckOn,
      iconCheckOff
    };
  },
  methods: {
    onClickAddEmissionPoint() {
      this.visibleAddEmissionPoint = true;
    },
    onCloseEmissionPointAddDrawer() {
      this.visibleAddEmissionPoint = false;
    },
    onClickEditEmissionPoint(emissionPoint, emissionPointIndex) {
      this.emissionPointIndexActived = emissionPointIndex;
      this.$root.$emit("onEmissionPointEdit", emissionPoint);
      this.visibleEditEmissionPoint = true;
    },
    onCloseEmissionPointEditDrawer() {
      this.visibleEditEmissionPoint = false;
    },
    onEmissionPointAdded({ getEmissionPoint }) {
      this.emissionPoints.push(getEmissionPoint);
    },
    onEmissionPointEdited({ getEmissionPoint }) {
      this.emissionPoints[this.emissionPointIndexActived] = getEmissionPoint;
    },
    async onConfirmDeleteEmissionPoint(emissionPoint, index) {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await tradeService
          .deleteEmissionPointId(emissionPoint)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.emissionPoints.splice(index, 1);
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    async setEmissionPointDefault(index) {
      try {
        let response = await tradeService.setEmissionPointDefault(
          this.emissionPoints[index]
        );
        if (response.data) {
          this.emissionPoints.forEach((emissionPoint, i) => {
            emissionPoint.is_default = i === index;
          });
          this.toggleApiResponse("success", true);
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    LinkPlusComponent,
    BusinessEmissionPointAddComponent,
    BusinessEmissionPointEditComponent,
    ButtonDeleteComponent
  },
  props: {
    setEmissionPoints: {
      type: Array
    },
    establishmentId: {
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
