<template>
  <section class="wrapper__business-stablishment-view" v-if="stablishment">
    <ButtonDeleteComponent
      class="float-right"
      :isShowButton="false"
      @onConfirmDelete="onConfirmDeleteStablishment"
    />
    <h4 class="h4 font-semi-bold">
      <a href="#" class="text-color-primary" @click="onStablishmentListBack"
        ><i class="el-icon-back font-bold"></i
      ></a>
      Información establecimiento
    </h4>

    <b-row class="my-4">
      <b-col xs="12" sm="12" md="12" lg="12">
        <b-row>
          <b-col xs="12" lg="12">
            <label for="" class="font-bold">Código del establecimiento</label>
            <p class="">{{ stablishment.code }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="12">
            <label for="" class="font-bold">Nombre Comercial</label>
            <p class="">{{ stablishment.name }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Ciudad</label>
            <p class="">{{ getEstablishmentCity }}</p>
          </b-col>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Dirección</label>
            <p class="">{{ stablishment.address }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Correo</label>
            <p class="">{{ stablishment.email }}</p>
          </b-col>
          <b-col xs="12" lg="6">
            <label for="" class="font-bold">Teléfono</label>
            <p class="">{{ stablishment.phone }}</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" lg="12">
            <label for="" class="font-bold">Sitio Web</label>
            <p class="">{{ stablishment.website ?? "-" }}</p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12">
        <h4 class="h4 font-semi-bold">Puntos de emisión</h4>
        <BusinessEmissionPointListComponent
          :setEmissionPoints="stablishment.emision_points"
          :establishmentId="stablishment.id"
          @getStablishments="onStablishmentListBack"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BusinessEmissionPointListComponent from "./EmissionPointList";
import ButtonDeleteComponent from "../../../../components/ButtonDelete";
import tradeService from "../../../services/tradeService";

export default {
  name: "BusinessStablishmentViewComponent",
  data() {
    return {};
  },
  computed: {
    getEstablishmentCity() {
      const { city } = this.stablishment;
      return city && city.name;
    }
  },
  methods: {
    onStablishmentListBack() {
      this.$emit("onStablishmentListBack");
    },
    async onConfirmDeleteStablishment() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await tradeService
          .deleteStablishmentId(this.stablishment)
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.onStablishmentListBack();

          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    }
  },
  components: {
    BusinessEmissionPointListComponent,
    ButtonDeleteComponent
  },
  props: {
    stablishment: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped></style>
