<template>
  <section class="wrapper__business-woocommerce-add px-4">
    <b-row>
      <b-col xs="12" lg="12">
        <h4 class="h4 font-semi-bold mb-4">
          Integración con Woocommerce
        </h4>
        <el-form :model="form" :rules="rules" ref="form">
          <b-row>
            <b-col xs="12" sm="12" md="12">
              <el-form-item prop="woocommerce_store_url" class="mb-4">
                <slot name="label">
                  <label class="font-semi-bold m-0">URL de la Tienda</label>
                </slot>
                <el-input
                  placeholder=""
                  class=""
                  v-model="form.woocommerce_store_url"
                ></el-input>
              </el-form-item>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12">
              <el-form-item prop="woocommerce_emision_point" class="mb-4">
                <SelectEmissionPointsComponent
                  @onChangeSelect="onChangeSelectEmissionPoints"
                  :setEmissionPointId="form.woocommerce_emision_point"
                />
              </el-form-item>
            </b-col>
          </b-row>

          <b-row>
            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="woocommerce_consumer_key" class="mb-4">
                <slot name="label">
                  <label class="font-semi-bold m-0">API Key</label>
                </slot>
                <el-input
                  placeholder=""
                  class=""
                  v-model="form.woocommerce_consumer_key"
                ></el-input>
              </el-form-item>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="woocommerce_consumer_secret" class="mb-4">
                <slot name="label">
                  <label class="font-semi-bold m-0">Secret Key</label>
                </slot>
                <el-input
                  placeholder=""
                  class=""
                  v-model="form.woocommerce_consumer_secret"
                ></el-input>
              </el-form-item>
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="6" md="6" xs="12" sm="12">
              <label class="font-semi-bold m-0">Sincronización</label>

              <el-form-item prop="woocommerce_is_sync_invoices" class="mt-4">
                <slot name="label">
                  <el-switch
                    v-model="form.woocommerce_is_sync_invoices"
                  ></el-switch>
                  <label class="font-semi-bold m-0 mx-2">Firmado digital</label>
                  <MoreInfoComponent
                    content="Al activar esta opción se inciará el proceso de firmado electrónico al SRI, se enviarán a facturas de forma automática las Órdenes emitidas en Woocommerce"
                  />
                </slot>
              </el-form-item>

              <el-form-item prop="woocommerce_is_sync_orders" class="mt-4">
                <slot name="label">
                  <el-switch
                    v-model="form.woocommerce_is_sync_orders"
                  ></el-switch>
                  <label class="font-semi-bold m-0 mx-2">Órdenes </label>
                  <MoreInfoComponent
                    content="Al activar esta opción se inciara la sincronización de Productos, Categorías, Clientes y Órdenes desde el Woocommerce"
                  />
                </slot>
              </el-form-item>
            </b-col>
            <b-col lg="6" md="6" xs="12" sm="12">
              <InputDatePickerComponent
                @onChangeDatePicker="onChangeDatePicker"
                prop="woocommerce_sync_date"
                placeholder=""
                title="Iniciar sincronización desde"
                :setDate="form.woocommerce_sync_date"
                :isDisabledDate="true"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col xs="12" sm="12">
              <ButtonPrimaryComponent
                class="float-right mt-4"
                title="Guardar"
                @onSaveClick="handleSaveClick"
              />
              <ButtonComponent
                className="float-right mt-4 mr-4 btn md__btn-third-secondary"
                title="Sincronizar"
                @onSaveClick="handleSinconizar"
              />
            </b-col>
          </b-row>
        </el-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Validate from "@/assets/validate";
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import ButtonComponent from "@/modules/dashboard/components/Button";
import MoreInfoComponent from "../../../../components/MoreInfo";
import SelectEmissionPointsComponent from "../../../../components/SelectEmissionPoints";
import InputDatePickerComponent from "@/modules/dashboard/components/InputDatePicker";

import tradeService from "../../../services/tradeService";
import moment from "moment";
const DATE_AT = moment().format("YYYY-MM-DD");

export default {
  name: "WoocommerceAdd",
  data() {
    return {
      form: {
        business_id: "",
        woocommerce_store_url: "",
        woocommerce_consumer_key: "",
        woocommerce_consumer_secret: "",
        woocommerce_is_sync_invoices: false,
        woocommerce_is_sync_orders: false,
        woocommerce_emision_point: "",
        woocommerce_sync_date: DATE_AT
      },
      rules: {
        woocommerce_store_url: [Validate.rules.required()],
        woocommerce_consumer_key: [Validate.rules.required()],
        woocommerce_consumer_secret: [Validate.rules.required()],
        woocommerce_sync_date: [Validate.rules.required()]
        // woocommerce_is_sync_invoices: [Validate.rules.required("change")],
        // woocommerce_is_sync_orders: [Validate.rules.required("change")]
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  watch: {},
  methods: {
    async getIntegrationsWoocommerce() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await tradeService
          .getIntegrationsWoocommerce({
            params: {
              business_id: this.getUser.user_business.id
            }
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.form = {
            ...this.form,
            ...data
          };
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            this.form.business_id = this.getUser.user_business.id;
            let response = await tradeService
              .addIntegrationsWoocommerce(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async handleSinconizar() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        this.form.business_id = this.getUser.user_business.id;
        let response = await tradeService
          .SyncIntegrationsWoocommerce({})
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.form.woocommerce_emision_point = emissionPointId;
    },
    onChangeDatePicker({ date }) {
      this.form.woocommerce_sync_date = date;
    }
  },
  components: {
    ButtonPrimaryComponent,
    MoreInfoComponent,
    SelectEmissionPointsComponent,
    InputDatePickerComponent,
    ButtonComponent
  },
  props: {},
  mounted() {
    this.getIntegrationsWoocommerce();
  }
};
</script>

<style lang="scss" scoped></style>
