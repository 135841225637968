<template>
  <section class="wrapper__business-bank-account-add p-4">
    <b-row>
      <b-col xs="12" lg="12">
        <div
          class="d-flex justify-content-center align-items-center flex-column"
        >
          <h4 class="h4 font-semi-bold mt-3 mb-4 text-center">
            Añadir cuenta bancaria
          </h4>
        </div>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="name" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Nombre Banco</label>
            </slot>
            <el-input placeholder="" class="" v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item prop="account_number" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Número cuenta</label>
            </slot>
            <el-input
              placeholder=""
              class=""
              v-model="form.account_number"
            ></el-input>
          </el-form-item>

          <el-form-item prop="type" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Tipo de Cuenta</label>
            </slot>

            <el-select placeholder="Seleccionar" class="" v-model="form.type">
              <el-option
                v-for="account in bankAccountTypes"
                :key="account.id"
                :label="account.name"
                v-model="account.name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="customer_name" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Nombre Titular o Empresa</label>
            </slot>
            <el-input
              placeholder=""
              class=""
              v-model="form.customer_name"
              rows="10"
            ></el-input>
          </el-form-item>

          <el-form-item prop="customer_document_number" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Cédula o RUC</label>
            </slot>
            <el-input
              placeholder=""
              class=""
              v-model="form.customer_document_number"
              rows="10"
            ></el-input>
          </el-form-item>

          <el-form-item prop="customer_email" class="mb-4">
            <slot name="label">
              <label class="font-semi-bold m-0">Email</label>
            </slot>
            <el-input
              placeholder=""
              class=""
              v-model="form.customer_email"
              rows="10"
            ></el-input>
          </el-form-item>

          <b-row>
            <b-col xs="12" sm="12">
              <ButtonPrimaryComponent
                class="center mt-4"
                title="Guardar"
                @onSaveClick="handleSaveClick"
              />
            </b-col>
          </b-row>
        </el-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";
import Validate from "@/assets/validate";
import businessService from "../../services/index";

import { bankAccountTypes } from "@/services/Utils";

export default {
  name: "BankAccountAdd",
  data() {
    return {
      bankAccountTypes,
      form: {
        name: null,
        account_number: null,
        type: null,
        customer_name: null,
        customer_document_number: null,
        customer_email: null
      },
      rules: {
        name: [Validate.rules.required()],
        account_number: [Validate.rules.required()],
        type: [Validate.rules.required("change")],
        customer_name: [Validate.rules.required()],
        customer_document_number: [Validate.rules.required()],
        customer_email: [Validate.rules.required()]
      }
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await businessService
              .addBankAccount(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, data, message } = response.data;
            if (success) {
              this.$emit("onBankAccountAdd", {
                bankAccount: data
              });
              this.$notifications.success({
                message
              });

              this.$refs.form.resetFields();
            }
          } catch (error) {
            return false;
          }
        }
      });
    }
  },
  components: {
    ButtonPrimaryComponent
  },
  props: {}
};
</script>

<style lang="scss" scoped></style>
